import React, { useState, useContext, useEffect } from "react";
import '../../App.css';
import { UserCartContext } from "../../context/AppContext";
import { useStripe, CardElement, useElements, AddressElement } from '@stripe/react-stripe-js';
import { newOrder, userfrontGetUser, userPayment } from '../../utils.js/api';
import { useNavigate } from "react-router";
import './cssStripe.css';
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function ProcessorStripe() {

  const { userCart, setUserCart } = useContext(UserCartContext);
  const [curPendingOrder, setCurPendingOrder] = useState({});
  useEffect(() => {
    if (Userfront.accessToken()) {
      userfrontGetUser(Userfront.accessToken())
      .then((res) => {
        setCurPendingOrder({
          userId: res.userId,
          purchased: [...userCart],
          dataStripe: {}
        });
      })
    } else {
      setCurPendingOrder({
        purchased: [...userCart],
        dataStripe: {}
      });
    }
  }, [userCart]);
    
  const navigate = useNavigate();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#87bbfd" }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
}

  const stripe = useStripe();

  const elements = useElements();

  const payHandle = async (event) => {
    event.preventDefault();
    const total = curPendingOrder.purchased.reduce((total, cur) => {
      return total + (cur.price * cur.attributes.qtySelected);
    },0);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardElement)
    });
    if (!error) {
      const { id } = paymentMethod;
      const amount = total;
      const description = 'description';
      try {
        userPayment(id, amount, description)
          .then(( { data } ) => {
            newOrder({...curPendingOrder, dataStripe: data, idStripe: data.id })
              .then(() => {
                setUserCart([]);
                navigate('/order-success', {
                  state: {
                    dataStripe: data,
                    order: {...curPendingOrder}
                  }
                })
              })
          })
      } catch(err) {
        console.log(err)
      }
      
    }
  }
  
  return (
    <div style={{margin: '15px' }} >
       <AddressElement options={{mode: 'billing' }} />
        <form onSubmit={payHandle} >
          <fieldset className='formgroup'>
          <div className='formrow' >
            <CardElement options={CARD_OPTIONS} />
          </div>
          </fieldset>
          <button type='submit' className='btn' >pay</button>
        </form>
    </div>
  );
}

export default ProcessorStripe;
import React, { useEffect, useState } from "react";
import styles from './Brand.module.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactMarquee from 'react-fast-marquee';
import { listProducts, readSupplier } from '../utils.js/api';
import { useMediaQuery } from 'react-responsive';

function Brand() {

  const navigate = useNavigate();

  const smallScreen = useMediaQuery({
    query: '(max-width: 584px)'
  });

  console.log(smallScreen)

  const params = useParams();
  const supplierId = params.supplier_id;

  const [brand, setBrand] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    readSupplier(supplierId)
      .then(setBrand)
  }, [supplierId]);

  useEffect(() => {
    listProducts(`?supplierId=${supplierId}`)
      .then(setProducts)
  }, [supplierId]);

  return (
    <div className={styles.container} >
      <div className={styles.name} >{brand.name}</div>
      <img src={brand.logo} className={styles.image} alt='logo' />
      <div className={styles.bio} >
        bio: {brand.bio}
      </div>
      <div className={styles.sustainability}>
        sustainability: {brand.sustainability_bio}
      </div>
      <div className={styles['all-products-link']} >
        <Link to={`/shop?page=1&supplierId=${supplierId}`} >All Products</Link>
      </div>
      <div className={styles.carousel} >
        <ReactMarquee speed={!smallScreen ? 5 : 3} pauseOnHover={true} gradientWidth={25} >
          {products.map((item) => <div style={{border: 'solid', margin: '15px' }} onClick={() => navigate(`/shop/${item.product_name}/id-${item.product_id}`)} ><img src={item.image} className={styles['carousel-img']} alt='item' /></div>)}
        </ReactMarquee>
      </div>
    </div>
  );
}

export default Brand;
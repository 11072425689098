import React, { useEffect, useState } from "react";
import styles from "./ShopSidebar.module.css";
import BrandList from "./BrandList";
import CategoryOptions from "./CategoryOptions";
import OrderOptions from "./OrderOptions";

function ShopSidebar({
  categories,
  queryParams,
  setQueryParams
}) {

  const [gen, setGen] = useState(queryParams.get('gender'))
  useEffect(() => {
    setGen(queryParams.get('gender'));
  }, [queryParams]);

  const genCheckbox = (event) => {
    console.log(event.target.id)
    if (event.target.id === 'women-check') {
      setQueryParams({ ...Object.fromEntries(queryParams), 'gender': 'women' });
    } else if (event.target.id === 'men-check') {
      setQueryParams({ ...Object.fromEntries(queryParams), 'gender': 'men' });
    }
  }

  return (

      <div className={styles.container} >

        {/*
        <div style={{borderBottom: "solid rgb(180, 180, 180) 1px", display: 'flex', textAlign: 'left', marginBottom: '15px' }} >
          <div style={{marginRight: '5px' }} >
            <label >Men</label>
            <input id='men-check' type='checkbox' className={styles.checkbox} checked={gen === 'men'} readOnly onClick={genCheckbox} />
          </div>
          <div style={{marginBottom: '15px' }} >
            <label >Women</label>
            <input id='women-check' type='checkbox' className={styles.checkbox} checked={gen === 'women'} readOnly onClick={genCheckbox} />
          </div>
        </div>
        */}

        <div className={styles['thrifted-container']} >
          <div style={{display: 'flex', fontSize: '12px'}}>include thrift / upcycled?</div>
          <div className={styles['thrifted-toggle']} >
            <button 
              onClick={() => {
                queryParams.delete('thrifted');
                setQueryParams({...Object.fromEntries(queryParams)});
              }}
              className={!queryParams.get('thrifted') ? styles['thrifted-checked'] : styles['thrifted-unchecked']}
              style={{marginLeft: '0px' }}
            >
              yes
            </button>
            |
            <button 
              onClick={() => setQueryParams({...Object.fromEntries(queryParams), thrifted: 'none'}) }
              className={queryParams.get('thrifted') === 'none' ? styles['thrifted-checked'] : styles['thrifted-unchecked']}
            >
              no
            </button>
            |
            <button 
              onClick={() => setQueryParams({...Object.fromEntries(queryParams), thrifted: 'only'})}
              className={queryParams.get('thrifted') === 'only' ? styles['thrifted-checked'] : styles['thrifted-unchecked']}
            >
              only
            </button>
          </div>
        </div>

        <div style={{order: '3' }} className={styles['categories-label']} >{queryParams.get('category') ? `Category: ${queryParams.get('category')}` : 'Categories' }</div>

        <div className={styles['category-container']} >
          <CategoryOptions categories={categories} />
        </div>
        
        <div className={styles['brand-container']} >
          <BrandList />
        </div>

      </div>
  );
}

export default ShopSidebar;

import React from "react";
import '../../App.css';
import { useNavigate } from "react-router";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function UserLogout() {

  const navigate = useNavigate();

  const userLogout = (event) => {
    event.preventDefault();
    Userfront.logout()
      .then((res) => {
        navigate('/login');
      })
  }

  return (
    <div >
      <button id='btn-logout' className='btn' onClick={userLogout} >log out</button>
    </div>
  );
}

export default UserLogout;
import { removeCookie } from './cookieHelper';
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");
const USERFRONT_API_KEY = process.env.REACT_APP_USERFRONT_API_KEY;
const API_BASE_URL = 'https://greenhauls-backend.onrender.com';
const BASE_URL_USERFRONT = 'https://api.userfront.com';

const headers = new Headers();
headers.append("Content-Type", "application/json");

// -------- products functions

export async function readProduct(productId) {
  const url = new URL(`${API_BASE_URL}/products/${Number(productId)}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

export async function listProducts(query) { // each query in the query string must be formatted ?databaseColumnName=desiredRowValue
  const url = new URL(`${API_BASE_URL}/products${query}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

export async function countProducts(query) { // each query in the query string must be formatted ?databaseColumnName=desiredRowValue
  const url = new URL(`${API_BASE_URL}/products/all/count${query}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

// -------- suppliers functions

export async function readSupplier(supplierId) {
  const url = new URL(`${API_BASE_URL}/suppliers/${supplierId}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

export async function listSuppliers() {
  const url = new URL(`${API_BASE_URL}/suppliers`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

// -------- userfront functions

export async function userfrontGetUser(token) {
  const url = new URL(`${BASE_URL_USERFRONT}/v0/self`);
  const options = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  const response = await fetch(url, options).then(res => res.json());
  return response;
}

/**
 * @param {obj} newAddress
 * @returns - user updated
 */
export async function userfrontUpdateAddresses(newAddress) {
  const userCurrent = await userfrontGetUser(Userfront.accessToken());
  await Userfront.user.update({
    data: {
      ...userCurrent.data,
      addresses: newAddress
    }
  });
  return await userfrontGetUser(Userfront.accessToken());
}

export async function userfrontUpdateAtc(cartItem) {
  const userCurrent = await userfrontGetUser(Userfront.accessToken());
  await Userfront.user.update({
    data: {
      ...userCurrent.data,
      userCart: [...userCurrent.data.userCart, cartItem]
    }
  })
  return await userfrontGetUser(Userfront.accessToken());
}

export async function userfrontUpdateItemRmv(id) {
  console.log(id)
  const userCurrent = await userfrontGetUser(Userfront.accessToken());
  await Userfront.user.update({
    data: {
      ...userCurrent.data,
      userCart: userCurrent.data.userCart.filter((item) => item.cartItemId !== id)
    }
  });
  return await userfrontGetUser(Userfront.accessToken());
}

export async function userfrontUpdateEmailPreferences(preferences) {
  const userCurrent = await userfrontGetUser(Userfront.accessToken());
  await Userfront.user.update({
    data: {
      ...userCurrent.data,
      emailPreferences: preferences
    }
  });
  return await userfrontGetUser(Userfront.accessToken());
}

export async function userfrontUpdateInformation(userInformation, userId) { // Userfront.user.update() doesnt work since it only allows changing name, image, username, and data
  const url = new URL(`https://api.userfront.com/v0/users/${userId}`);
  const options = {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USERFRONT_API_KEY}`
    },
    body: JSON.stringify(userInformation)
  }
  const response = await fetch(url, options).then((res) => res.json());
  return response;
}

/*
export async function userfrontUpdateNewOrder(order) {
  const userCurrent = await userfrontGetUser(Userfront.accessToken());
  await Userfront.user.update({
    data: {
      ...userCurrent.data,
      userCart: [],
      previousOrders: [...userCurrent.data.previousOrders, order]
    }
  });
  const response = await newOrder(order);
  return await userfrontGetUser(Userfront.accessToken());
}
*/

// -------- functions stripe

export async function newPaymentIntent(items) {
  const url = new URL(`${API_BASE_URL}/checkout/create-payment-intent`);
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      items: items
    })
  }
  console.log(items)
  const response = await fetch(url, options).then(res => res.json());
  console.log(response)
  return response
}

export async function userPayment(id, amount, description) {
  const url = new URL(`${API_BASE_URL}/checkout/payment`);
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      id: id,
      amount: amount,
      description: description
    })
  };
  const response = await fetch(url, options).then(res => res.json());
  return response;
}

// -------- userOrders functions

export async function newOrder(order) {

  console.log(order)

  const url = new URL(`${API_BASE_URL}/user-orders`);
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(order)
  };
  const response = await fetch(url, options).then(res => res.json());

  if (Userfront.accessToken()) {
    const userCurrent = await userfrontGetUser(Userfront.accessToken());
    await Userfront.user.update({
      data: {
        ...userCurrent.data,
        userCart: []
      }
    });
  } else {
    for (let item of order.purchased) {
      removeCookie(item.cartItemId);
    }
  }

  return response;
}

export async function listOrders(userId) {
  const url = new URL(`${API_BASE_URL}/user-orders/${userId}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}

export async function readOrder(idStripe) {
  const url = new URL(`${API_BASE_URL}/user-orders/users/${idStripe}`);
  const response = await fetch(url).then(res => res.json());
  return response.data;
}
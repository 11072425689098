import React, { useState } from "react";
import { useStripe, PaymentElement, useElements, AddressElement } from '@stripe/react-stripe-js';

function Form() {
  
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const [paymentRequest, setPaymentRequest] = useState(null);

  /* -------- google and apple pay (must be over https)
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      console.log(pr)
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);
  console.log(paymentRequest)
  */

  const formSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://localhost:3000/order-success',
        receipt_email: 'greenhauls@gmail.com'
      }
    });

    console.log(error)
    
    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  }

  return (
    <form onSubmit={formSubmit}>
      <AddressElement options={{mode: 'shipping' }} />
      <PaymentElement options={{layout: 'tabs' }} />
      <button type='submit' className='btn' >pay</button>
    </form>
  )
}

export default Form;
import React from "react";
import styles from './OrderOptions.module.css';

function OrderOptions( { queryParams, setQueryParams } ) {

  const orderChange = (event) => {
    const order = event.target.value;
    setQueryParams({...Object.fromEntries(queryParams), order: order});
  }
  
  return (
    <div className={styles.container} >
      <select name='order' className={styles['order-select']} onChange={orderChange} value={queryParams.get('order') ? queryParams.get('order') : 'order'} >
        <option value='order' style={{display: 'none'}} >order by</option>
        <option value='featured' >featured</option>
        <option value='created_at' >newest</option>
        <option value='price-asc' >price ascending</option>
        <option value='price-desc' >price descending</option>
      </select>
    </div>
  )
}

export default OrderOptions;
import React from "react";
import Userfront from '@userfront/react';

function UserPasswordReset() {

  const PasswordResetForm = Userfront.build({
    toolId: "dmmkab"
  });

  return (
    <div >
      <PasswordResetForm />
    </div>
  );
}

export default UserPasswordReset;
import React, { useState } from "react";
import '../../App.css';
import styles from './Form.module.css';
import { useNavigate } from "react-router";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function RegisterCustom() {
    
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const register = (event) => {
    event.preventDefault();
    Userfront.signup({
      method: 'password',
      name: name,
      email: email,
      password: password,
      data: {
        addresses: [],
        userCart: [],
        previousOrders: [],
        emailPreferences: {
          subscribed: false
        }
      }
    })
      .then((res) => {
        navigate('/my-account');
      })
      .catch((err) => {
        setError(err);
      })
  }

  return (
    <div >
      <form onSubmit={register} className={styles.container} >
        {error ? error.toString() : null}
        <label htmlFor='name-reg' >
          name
          <input id='name-reg' type='text' className={styles.input} value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label htmlFor='email-reg' >
          email
          <input id='email-reg' type='text' className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label htmlFor='pwd-reg' >
          password
          <input id='pwd-reg' type='text' className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type='submit' className='btn' style={{width: '15%' }} >register</button>
      </form>
    </div>
  );
}

export default RegisterCustom;
import React from "react";
import Form from "../components/Register/Form";

function Register() {

  return (
    <div >
      <Form />
    </div>
  );
}

export default Register;
/**
 * @param {str} cname (the cookie name)
 */
export function retrieveCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export function retrieveCartItems() {
  let itemsArray = [];
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf('cartItem') === 0) {
      itemsArray.push(c);
    }
  }
  itemsArray = itemsArray.map((item) => {
    const i = item.indexOf('=');
    const obj = JSON.parse(item.slice(i + 1));
    obj.cartId = item.slice(0, i);
    return obj;
  })
  return itemsArray;
}

/**
 * @param {str} cname (the cookie name)
 * @param {str} value (the cookie value)
 * @param {num} exp (days until exp)
 */
export function newCookie(cname, value, exp) {
  const d = new Date();
  d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + value + ";" + expires + ";path=/";
}

export function removeCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
import React from "react";

function Faq() {
  return (
    <div style={{textAlign: 'left', margin: '20px' }} >
      <p>
        <span >FAQ</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >What is Greenhauls?</span>
      </p>
      <p>
        <span >
          Greenhauls is a sustainable fashion marketplace that strives to
          showcase the best rising creators in the sustainability space.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          How do you know all of the clothing is sustainable?
        </span>
      </p>
      <p>
        <span >
          All of the brands on Greenhauls have been carefully vetted to ensure
          the garments they&rsquo;re selling on our marketplace are sustainable.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >Where do you ship to?</span>
      </p>
      <p>
        <span >
          We are currently shipping to the US.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          How long is shipping going to take?
        </span>
      </p>
      <p>
        <span >
          The shipping time is variable depending on the brand and their
          product. The item description will have an estimate of how long it
          will take to be shipped. You will receive an email confirmation
          inclusive of the tracking number following shipment. If your item i
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >What is your return policy?</span>
      </p>
      <p>
        <span >
          All orders are eligible for a size exchange as long as the desired
          size is available. If the product received is not &ldquo;as
          described,&rdquo; the item can be refunded within 7 days of receiving
          the product. Please note, proof must be provided in order for a
          successful refund.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          For additional inquiries, email support@greenhauls.com
        </span>
      </p>
    </div>
  );
}

export default Faq;

import React, { useState } from "react";
import '../../App.css';
import styles from './CategoryOptions.module.css';
import { useSearchParams } from 'react-router-dom';
import arrow from '../../image/arrow.svg';

function CategoryOptions( { categories } ) {
  
  const [queryParams, setQueryParams] = useSearchParams(); // queryParams must be defined within the component rather than passed in as a prop from ShopSidebar or else it will break
  
  const openInitial = {};
  for (let category of categories) {
    // openInitial[category] = false;
    openInitial[category.name] = false;
    openInitial.tier = category.tier;
  }
  
  const [openCategory, setOpenCategory] = useState({...openInitial});

  const dropdownHandle = (event) => {
    const category = event.target.id;
    const prevValue = openCategory[category]
    setOpenCategory({
      ...openInitial,
      [category]: !prevValue
    })
  }

  function SidebarCategory( { category } ) {
    const subCategories = category.subCategories;
    /*
    let margPercent = 0;
    for (let i = 1; i < category.tier; i++) {
      margPercent += 25;
    }
    */
    const tierStyle = {
      display: 'flex',
      backgroundColor: 'white'
      // marginLeft: `${margPercent}%`,
    }
    return (
      <div className={styles['category-container']} style={category.tier === 1 ? {boxShadow: '3px 3px 1px rgb(180, 180, 180)'} : null }>
        <div style={tierStyle} >
          <div className={styles.name} onClick={() => {
            queryParams.delete('src');
            setQueryParams({...Object.fromEntries(queryParams), 'category': category.name, 'page': 1})
          }} >
            {category.name}
          </div>
          {subCategories.length > 0 ?
            <div id={category.name} name={category.name} className={styles['arrow-container']} onClick={dropdownHandle} >
              <img src={arrow} className={styles.arrow} style={openCategory[category.name] ? {transform: 'rotate(-90deg)', transition: 'all 0.2s' } : null } />
            </div>
            : 
            null}
          { /*
          {subCategories.length > 0 ?
            <div style={{display: 'flex', alignItems: 'center', width: '100%' }} >
            <hr style={{width: '100%' }} />
            </div>
            : 
            null}
          */ }
        </div>
        <div >
          {openCategory[category.name] === true ? <CategoryOptions categories={subCategories} /> : null}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container} style={openCategory.tier === 3 ? {borderBottom: 'none'} : null } >
        {openCategory.tier !== 1 ? <hr align='left' className={styles.hr} /> : null}
        {categories.map((category) => <SidebarCategory key={category.name} category={category} />)}
      </div>
    </>
  )
}

export default CategoryOptions;
import React, { useEffect, useState } from "react";
import OrderSuccessItem from "../components/OrderSuccess/OrderSuccessItem";
import { useLocation } from "react-router";
import { readOrder } from "../utils.js/api";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function OrderSuccess() {

  const { state } = useLocation();

  const [order, setOrder] = useState({});
  useEffect(() => {
    readOrder(state.dataStripe.id)
      .then(setOrder)
  }, [state.dataStripe.id]);

  if (state.dataStripe.status === 'succeeded' && order?.purchased) {
    return (
      <div >
        <h3 >Order Success!</h3>
        <div >Order_Id: {state.dataStripe.id}</div>
        <div>Payment Confirmation:</div>
        Total: {state.dataStripe.amount}
        <ul >
          {order?.purchased.map((item) => {
            return <OrderSuccessItem item={item} key={`${order.order_id}${item.id}`} />
          })}
        </ul>
      </div>
    );
  }
}

export default OrderSuccess;
import React from "react";
import styles from './Product.module.css';
import { useNavigate } from 'react-router-dom';

function Product( { product } ) {
  
  const navigate = useNavigate();

  const clickHandle = () => {
    navigate(`/shop/${product.product_name}/id-${product.product_id}`)
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.container} onClick={clickHandle} >
      <img className={styles.image} src={product.image} alt='image_product' />
      <div className={styles.attributes} style={{fontWeight: 'bold', fontFamily: "'Baloo 2', cursive" }} >{product.product_name}</div>
      <div className={styles.attributes} >price: {product.price}</div>
    </div>
  );
}

export default Product;
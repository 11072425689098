import React, { useContext, useEffect, useState } from "react";
import '../App.css';
import styles from './Cart.module.css';
import { UserCartContext } from "../context/AppContext";
import CartItem from '../components/Cart/CartItem';
import { Link } from "react-router-dom";
import Userfront from '@userfront/react';
Userfront.init("rbvzmr6b");

function Cart() {

  const { userCart } = useContext(UserCartContext);

  const [total, setTotal] = useState(userCart.reduce((total, cur) => {
    return total + (cur.price * cur.attributes.qtySelected)
  },0));
  useEffect(() => {
    setTotal(userCart.reduce((total, cur) => {
      return total + (cur.price * cur.attributes.qtySelected)
    },0));
  }, [userCart]);

  return (
    <div className={styles.container} >
      <div className={styles.label} >My Haul</div>
      <div className={styles['right-left-container']} >
        <div className={styles['left-container']} >
            {userCart.length === 0 ? <div style={{marginLeft: '15vw' }}>0 Items</div> : null}
            {userCart.map((item) => <CartItem item={item} setTotal={setTotal} key={item.cartItemId} /> )}
        </div>
        <hr className={styles.hr} />
        <div className={styles['right-container']} >
          total: {total}
          <br/>
          <div style={{fontSize: '.8em' }} >{'(shipping / tax not calculated)'}</div>
          {userCart.length > 0 ? <Link to='/checkout-stripe' style={{textDecoration: 'none' }} ><button className='btn' >Continue to shipping</button></Link> : null}
        </div>
      </div>
    </div>
  );
}

export default Cart;
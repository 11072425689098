import React, { useEffect, useState } from "react";
import '../../App.css';
import styles from './Form.module.css';
import { useNavigate } from "react-router";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function Form( { fromCheckout } ) {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState(null);
  
  const [redirect, setRedirect] = useState('/my-account');
  useEffect(() => {
    if (fromCheckout) {
      setRedirect('/shipping');
    }
  }, [fromCheckout]);

  const login = (event) => {
    event.preventDefault();
    Userfront.login({
      method: 'password',
      email: email,
      password: pwd,
      redirect: redirect
    })
  }

  return (
    <div className={styles.container} >
      <form onSubmit={login} >
        <div style={{display: 'flex', flexDirection: 'column' }} >
          {error ? error.toString() : null}
          <label htmlFor='login-email' >
            email
            <input id='login-email' className={styles.input} type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <label htmlFor='login-pwd' >
            password
            <input id='login-pwd' className={styles.input} type='password' value={pwd}  onChange={(e) => setPwd(e.target.value)} />
          </label>
        </div>
        <button type='submit' className='btn' style={{margin: '15px' }} >login</button>
      </form>
    </div>
  )
}

export default Form;
import React, { useEffect, useState } from "react";
import styles from './BrandList.module.css';
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { listSuppliers } from "../../utils.js/api";

function BrandList() {
  const [queryParams, setQueryParams] = useSearchParams();

  const [suppliersOptions, setSuppliersOptions] = useState([]);

  const [openBrandMenu, setOpenBrandMenu] = useState(false);

  useEffect(() => {
    listSuppliers().then((res) => {
      const formatted = res.map((supplier) => { // the results formatted for use with react-select as the options prop
        return { value: supplier.supplier_id, label: supplier.name };
      });
      setSuppliersOptions([{value: 'all', label: 'all brands'}, ...formatted])
    });
  }, []);

  const dropdownHandle = (event) => {
    if (event.length < 1) {
      queryParams.delete("supplierId");
      setQueryParams({ ...Object.fromEntries(queryParams), 'page': 1 });
      return;
    }
    if (event[event.length - 1].value === "all") {
      queryParams.delete("supplierId");
      setQueryParams({ ...Object.fromEntries(queryParams), 'page': 1 });
      window.location.reload();
      return;
    }
    const newParam = event.map((supplier) => supplier.value).join("-");
    setQueryParams({'page': 1});
    setQueryParams({
      ...Object.fromEntries(queryParams),
      'supplierId': newParam,
      'page': 1
    });
  };

  const styleSelect = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderRadius: '5px',
      borderTopLeftRadius: openBrandMenu ? '0px' : null,
      borderBottomLeftRadius: openBrandMenu ? '0px' : null,
      backgroundColor: '#292929',
      cursor: 'pointer',
      border: 'none',
      boxShadow: 'none',
      fontSize: '12px',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontFamily: "'Baloo 2', cursive;",
      color: 'white'
    }),
    indicatorContainer: (baseStyles) => ({
      ...baseStyles,
      padding: '0px'
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: '#292929',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      "::-webkit-scrollbar": {
        width: '8px'
      },
      "::-webkit-scrollbar-track": {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3);',
        borderRadius: '5px'
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: 'darkgrey',
        borderRadius: '5px'
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      }
    }),
    option: (baseStyles, { isFocused } ) => ({
      ...baseStyles,
      color: isFocused ? 'black' : 'white',
      backgroundColor: isFocused ? 'white' : null,
      fontFamily: "'Baloo 2', cursive;",
      cursor: 'pointer'
    }),
  }
  
  return (
    <div className={openBrandMenu ? styles['open-container'] : styles.container} >
      <Select 
        onChange={dropdownHandle} 
        options={suppliersOptions} 
        placeholder='All Brands' 
        onMenuOpen={() => setOpenBrandMenu(true)} 
        onMenuClose={() => setOpenBrandMenu(false)} 
        isMulti 
        styles={styleSelect}
      />
    </div>
  );
}

export default BrandList;

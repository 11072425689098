import React, { useEffect, useState } from "react";
import styles from './ShopDropdown.module.css';
import { Link } from "react-router-dom";
import { listSuppliers } from "../utils.js/api";

function ShopDropdown( { shopDropdownOpen, setShopDropdown, gen } ) {
  
  if (gen === 'women') {
    gen = '&gender=women';
  } else {
    gen = '&gender=men';
  }

  const [suppliers, setSuppliers] = useState([]);
  useEffect(() => {
    if (shopDropdownOpen && suppliers.length === 0) {
      listSuppliers()
        .then(setSuppliers)
    }  
  }, [shopDropdownOpen])

  const [thrifted, setThrifted] = useState('') // there are 3 possible options '&thrifted=only' returns only thrift, '&thrifted=none' returns no thrift, and empty includes both
  
  
  if (shopDropdownOpen) {
    return (
      <div className={styles.container} >
        {/* <img src={require('../image/ezgif.gif')} style={{position: 'absolute', opacity: '0.5', width: '100vw', zIndex: '-1' }} /> */ }
        <div >
          <div style={{textAlign: 'left'}}>
            include thrift / upcycled clothing?
          </div>
          <div className={styles['thrifted-toggle']} >
              <button id='thrifted-combined' onClick={() => setThrifted('')} className={thrifted === '' ? styles['thrifted-checked'] : styles['thrifted-unchecked'] } >yes</button>
              |
              <button id='thrifted-false' onClick={() => setThrifted('&thrifted=none')} className={thrifted === '&thrifted=none' ? styles['thrifted-checked'] : styles['thrifted-unchecked']} >no</button>
              |
              <button id='thrifted-true' onClick={() => setThrifted('&thrifted=only')} className={thrifted === '&thrifted=only' ? styles['thrifted-checked'] : styles['thrifted-unchecked'] } >only</button>
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-between', gap: '50px' }} >
          
          <div className={styles['categories-container']} >
            <h2 style={{textAlign: 'left', marginBottom: '3px', fontFamily: "'Caveat', cursive", fontSize: '1.9em' }} >Categories:</h2>
            <hr className={styles.hr} />
            <Link to={`/shop?page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >All</Link>
            <Link to={`/shop?category=Tops&page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >tops</Link>
            <Link to={`/shop?category=Bottoms&page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >bottoms</Link>
            <Link to={`/shop?category=Accessories&page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >accessories</Link>
            <Link to={`/shop?category=Footwear&page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >footwear</Link>
            <Link to={`/shop?category=Outerwear&page=1${gen}${thrifted}`} className={styles['link-underline']} onClick={() => setShopDropdown(false)} >outerwear</Link>
          </div>
          
          <div className={styles['brand-container']} >
            <h2 style={{marginBottom: '3px', textAlign: 'left', fontFamily: "'Caveat', cursive", fontSize: '1.9em' }} >Brands:</h2>
            <hr className={styles.hr} />
            <ul className={styles['brand-scroll']} >
              {suppliers.map((supplier) => {
                return <li ><Link to={`/shop?page=1&supplierId=${supplier['supplier_id']}${thrifted}`} onClick={() => setShopDropdown(false)} className={styles['link-underline']} >{supplier.name}, </Link></li>
              })}
            </ul>
          </div>
          
        </div>
        
      </div>
    );
  }
}

export default ShopDropdown;

import React from "react";
import styles from './Login.module.css'
import { Link, useLocation } from "react-router-dom";
import Form from "../components/Login/Form";

function Login() {

  const location = useLocation();

  return (
    <div className={styles.container} >
      <Form fromCheckout={location.state?.fromCheckout} />
      <Link to={'/register'} className={styles.link} >create account</Link>
      <Link to={'/user-password-reset'} className={styles.link} >forgot password?</Link>
    </div>
  );

}

export default Login;
import React, { useState, useEffect } from "react";
import styles from './Parallax.module.css';
import kajet from '../../image/kajet-minnn.webp'
import logo from '../../image/logo-alt.png'
import downArrow from '../../image/down-arrow.svg';
import leafsLeft from '../../image/leafs-left-blur-min.webp';
import leafsRight from '../../image/leafs-right-blur-min.webp';
import sideLeaves from '../../image/side-leaves.webp';
import sideLeavesRight from '../../image/side-leaves-right.webp';

function Parallax() {

  const [winScrollY, setWinScrollY] = useState(0);

  const handleScroll = () => {
    requestAnimationFrame(() => setWinScrollY(window.scrollY))
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <div className={styles.container} >
      <img src={kajet} className={styles.mountains} style={{transform: `translate3d(0px, ${winScrollY * 0.8}px, 0px)` }} />
      <div className={styles.title} style={{opacity: `${100 - (winScrollY * .39)}%` }} >
        {/* <h1 style={{fontSize: '3em', width: '100%' }} ><img style={{width: '50%'}} src={logo} /></h1> */}
        <h1 className={styles.logo} >greenhauls</h1>
        <img className={styles['down-arrow']} src={downArrow} />
      </div>
      <img src={leafsLeft} className={styles['leafs-left']} style={{transform: `translate3d(-${winScrollY}px, 0px, 0px)`, filter: 'blur(3px)' }} />
      <img src={leafsRight} className={styles['leafs-right']} style={{transform: `translate3d(${winScrollY}px, 0px, 0px)`, filter: 'blur(3px)' }} />
      <img src={sideLeaves} className={styles['side-leaves']} style={{left: '0px' }} />
      <img src={sideLeavesRight} className={styles['side-leaves']} style={{right: '0px' }} />
    </div>
  );
}

export default Parallax;

import React, { useState } from "react";
import styles from './Navigation.module.css';
import { Link } from "react-router-dom";
import ShopDropdown from "./ShopDropdown";
import vineRight from '../image/vine-right.svg';
import vineLeft from '../image/vine-left.svg';

function Navigation() {

  const [shopDropdownOpen, setShopDropdownOpen] = useState(false);
  const [arrowStyle, setArrowStyle] = useState({})
  const [gen, setGen] = useState('men');
  
  //onClick={() => setShopDropdownOpen(cur => !cur) }
  const mouseHover = (event) => {
    if (event.target.getAttribute('name') === 'menDropdown') {
      setGen('men');
    } else if (event.target.getAttribute('name') === 'womenDropdown') {
      setGen('women');
    }
    setShopDropdownOpen(true)
    setArrowStyle({
      transform: 'rotate(-135deg)',
      transition: 'transform ease 0.2s'
    })
  }

  const mouseHoverOut = () => {
    setShopDropdownOpen(false)
    setArrowStyle({
      transform: 'rotate(-45deg)',
      transition: 'transform ease 0.2s'
    })
  }
  
  return (
    <nav className={styles.nav} style={shopDropdownOpen ? {borderBottom: 'none'} : null} >

      {/* <div ><img src={vineLeft} style={{width: '25px' }} /></div> */}

      <div className={styles.dropdown} >
        <div name='menDropdown' className={styles['men-women']} onMouseOver={mouseHover} onMouseOut={mouseHoverOut} onClick={mouseHover} style={{cursor: 'pointer', height: '100%' }} >Men <span name='menDropdown' className={styles.arrow} style={gen === 'men' ? arrowStyle : null} ></span></div>
        <div onMouseOver={mouseHover} onMouseOut={mouseHoverOut} >
          <ShopDropdown shopDropdownOpen={shopDropdownOpen} setShopDropdownOpen={setShopDropdownOpen} gen={gen}  />
        </div>
      </div>

      <div className={styles.dropdown} >
        <div name='womenDropdown' className={styles['men-women']} onMouseOver={mouseHover} onMouseOut={mouseHoverOut} onClick={mouseHover} style={{cursor: 'pointer', height: '100%' }} >Women <span name='womenDropdown' className={styles.arrow} style={gen === 'women' ? arrowStyle : null} ></span></div>
        <div onMouseOver={mouseHover} onMouseOut={mouseHoverOut} >
          <ShopDropdown shopDropdownOpen={shopDropdownOpen} setShopDropdownOpen={setShopDropdownOpen} gen={gen} />
        </div>
      </div>

      <div className={styles['link-underline']} >
        Trending
      </div>

      <Link to={`/shop?page=1&order=created_at`} className={styles['link-underline']} >
        New Arrivals
      </Link>

      <div className={`${styles['blog']} ${styles['link-underline']}`}>
        Blog
      </div>

      {/* <div ><img src={vineRight} style={{width: '25px' }}/></div> */}

    </nav>
  );
}

export default Navigation;
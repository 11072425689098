import React, { useEffect, useState } from "react";
import '../../App.css';
import { listOrders } from "../../utils.js/api";

function MyOrders( { user } ) {

  const [previousOrders, setPreviousOrders] = useState([]);

  useEffect(() => {
    listOrders(user.userId)
      .then((res) => {
        setPreviousOrders(res)
      })
  }, [user]);

  function Order( { order } ) {
    const [viewable, setViewable] = useState(false);
    return (
      <>
        <li style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          <div >order_id: {order['order_id']}</div>
          <div >date ordered: {order['updated_at']}</div>
          <div >total: {order.total}</div>
          <button className='btn' onClick={() => setViewable((cur) => !cur)} > {viewable ? 'close' : 'view details'}</button>
          {viewable ? 
            <ul >
              {order.purchased.map((item) => {
                return (
                  <li >
                    {item.name} {item.attributes.sizeSelected} {item.attributes.colorSelected.color}
                    <br/>
                    price: {item.price} qty: {item.attributes.qtySelected}
                  </li>
                );
              })}
            </ul>
            :
            null
          }
        </li>
      </>
    );
  }

  return (
    <div style={{overflowY: 'auto' }} >
      <ul style={{paddingLeft: '0px' }} >
        {previousOrders.map((order) => {
          return (
            <Order order={order} />
          );
        })}
      </ul>
    </div>
  );
}

export default MyOrders;
import React, { useEffect, useState } from "react";
import '../../App.css';
import { userfrontUpdateEmailPreferences } from "../../utils.js/api";

function EmailPreferences( { user, setUser } ) {

  const [subscribed, setSubscribed] = useState(false);
  useEffect(() => {
    setSubscribed(user?.data?.emailPreferences?.subscribed);
  }, [user]);

  const [disabled, setDisabled] = useState(false);


  const subscribe = () => {
    setDisabled(true);
    userfrontUpdateEmailPreferences({subscribed: true})
      .then(setUser)
      .then(() => setSubscribed(true))
      .then(() => setDisabled(false))
  }

  const unsubscribe = () => {
    setDisabled(true);
    userfrontUpdateEmailPreferences({subscribed: false})
      .then(setUser)
      .then(() => setSubscribed(false))
      .then(() => setDisabled(false))
  }

  return (
    <div style={{margin: '15px' }} >
      {subscribed ?
        <div >subscribed: do you want to unsubscribe? <button className='btn' onClick={unsubscribe} disabled={disabled} >unsubscribe</button></div>
        :
        <div >unsubscribed: would you like to subscribe? <button className='btn' onClick={subscribe} disabled={disabled} >subscribe</button></div>
      }
    </div>
  );
}

export default EmailPreferences;
import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './pages/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import MyAccount from './pages/MyAccount';
import Cart from './pages/Cart';
import TopBanner from './components/TopBanner';
import TopBar from './components/TopBar';
import Shop from './pages/Shop';
import Wishlist from './pages/Wishlist';
import ShopProduct from './pages/ShopProduct';
import Brand from './pages/Brand';
import Register from './pages/Register';
import Login from './pages/Login';
import UserPasswordReset from './pages/UserPasswordReset';
import Checkout from './pages/Checkout';
import OrderSuccess from './pages/OrderSuccess';
import { UserCartContext, NavVisibleContext } from './context/AppContext';
import { retrieveCartItems } from './utils.js/cookieHelper';
import { userfrontGetUser } from './utils.js/api';
import Userfront from '@userfront/core';
import Shipping from './pages/Shipping';
import Navigation from './components/Navigation';
import SiteFooter from './components/SiteFooter';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';
import ShippingReturns from './pages/ShippingReturns';
import TermsConditions from './pages/TermsConditions';
import CheckoutStripe from './pages/CheckoutStripe';
Userfront.init("rbvzmr6b");

function App() {
  
  // -------- UserCartContext logic
  const [userCart, setUserCart] = useState(
    Userfront.accessToken() ?
      [] // doesnt update for the user in real time must instead use the useEffect below with userfrontGetUser(Userfront.accessToken()).then(res => res.data.userCart). Only added here for the first render a user gets
      :
      retrieveCartItems()
  )
  useEffect(() => {
    if (Userfront.accessToken()) {
      userfrontGetUser(Userfront.accessToken())
        .then((res) => {
          setUserCart(res.data?.userCart);
        });
    }
  }, []);
  // --------

  // -------- NavVisibleContext logic
  const location = useLocation();

  const [navVisible, setNavVisible] = useState(true);

  let oldScroll = window.pageYOffset;
  window.onscroll = function(e) {
    let curScroll = window.pageYOffset;
    if (oldScroll > curScroll) {
      setNavVisible(true);
    } else {
      setNavVisible(false);
    }
    oldScroll = curScroll;
  }

  useEffect(() => {
    setNavVisible(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname])
  // --------

  const [pageLoaded, setPageLoaded] = useState(false)
  window.addEventListener('load', function () {
    setPageLoaded(true)
  })

  return (
    <div className={location.pathname === '/' ? 'App background' : 'App' } >
      {/*
      {location.pathname === '/' && !pageLoaded ?
        <div style={{width: '100vw', height: '100vh', position: 'fixed', zIndex: 1000, backgroundColor: 'white' }} >
          <img src={truck} style={{margin: 'auto', width: '150px', marginTop: '39vh' }} />
          </div>
        :
        null
      }
      */}
      <UserCartContext.Provider value={{userCart, setUserCart}} >
      <NavVisibleContext.Provider value={{navVisible, setNavVisible}} >
        <div className='top' >
          <div className='top-banner' style={!navVisible ? {top: '-18px', transition: 'top 0.3s'} : {top: '0%', transition: 'top 0.3s' } } >
            <TopBanner />
          </div>
          <div className='top-bar' style={!navVisible ? {top: '0px', transition: 'top 0.3s'} : {top: '18px', transition: 'top 0.3s'}} >
            <TopBar />
          </div>
          <div className='navigation' style={!navVisible ? {top: '63px', opacity: '0%'} : {opacity: '100%', transition: 'opacity 0.15s 0.3s' } } >
            <Navigation />
          </div>
        </div>
          <div className='pages'  >
            <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/my-account' element={<MyAccount />} />
              <Route path='/user-password-reset' element={<UserPasswordReset />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/shipping' element={<Shipping />} />
              <Route path='/checkout' element={<Checkout />} />
              <Route path='/checkout-stripe' element={<CheckoutStripe />} />
              <Route path='/order-success' element={<OrderSuccess />} />
              <Route path='/shop' element={<Shop />} />
              <Route path='/shop/:product_name/:product_id' element={<ShopProduct />} />
              <Route path='/brands/:supplier_id' element={<Brand />} />
              <Route path='/wishlist' element={<Wishlist />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/FAQ' element={<Faq />} />
              <Route path='/shipping-returns' element={<ShippingReturns />} />
              <Route path='/terms-conditions' element={<TermsConditions />} />
            </Routes>
            <div className='site-footer' >
              <SiteFooter />
            </div>
          </div>
          
      </NavVisibleContext.Provider>
      </UserCartContext.Provider>
    </div>
  );
}

export default App;

import React from "react";
import styles from './TopBanner.module.css';
import ReactMarquee from 'react-fast-marquee';

/**
 * utilizes the react-fast-marquee npm package
 * @returns TopBanner component
 */

function TopBanner() {

  return (
    <ReactMarquee className={styles.container} pauseOnHover='true' speed='30' gradientColor='#77ea77' >
      TopBanner
    </ReactMarquee>
  );
  
}

export default TopBanner;
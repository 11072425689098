import React, { useContext, useState } from "react";
import '../../App.css';
import styles from './CartItem.module.css';
import { UserCartContext } from "../../context/AppContext";
import { userfrontUpdateItemRmv } from "../../utils.js/api";
import { removeCookie, retrieveCartItems } from '../../utils.js/cookieHelper';
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function CartItem( { item, setTotal } ) {

  const { setUserCart } = useContext(UserCartContext);

  const [qty, setQty] = useState(item.attributes.qtySelected)
  const qtyHandle = (event) => {
    
    if (event.target.id === 'plus') {
      if (qty + 1 > getQtyMax()) {
        return
      } else {
        setQty((cur) => cur + 1);
        setTotal((cur) => cur + item.price);
      }
    }

    if (event.target.id === 'minus') {
      if (qty - 1 < 1) {
        return
      } else {
        setQty((cur) => cur - 1)
        setTotal((cur) => cur - item.price);
      }
    }

  }

  function getQtyMax() {
    let qtyMaxed = 1;
    const filtered = item.attributes.colorSelected.sizes.filter((size) => {
      return size[0] === item.attributes.sizeSelected;
    })[0]
    if (filtered) qtyMaxed = filtered[1];
    return qtyMaxed;
  }
  
  const rmv = async (event) => {
    if (Userfront.accessToken()) {
      userfrontUpdateItemRmv(event.target.id)
        .then((res) => {
          setUserCart(res.data.userCart);
        })
    } else {
      removeCookie(item.cartId);
      setUserCart(retrieveCartItems().filter((item) => item.cartItemId !== event.target.id));
    }
  }

  return (
    <div >
      <img src={item.attributes.colorSelected.image} style={{width: '15%' }} alt={item.name} />
      <br/>
      {item.name}
      <br/>
      size: {item.attributes.sizeSelected}
      price: {item.price}
      <div>
        <input type='number' className={styles['qty-input']} min={1} max={getQtyMax()} value={qty} onChange={qtyHandle} readOnly />
        <button id='plus' className='btn' style={{padding: '0px 5px 0px 5px', border: 'none' }} onClick={qtyHandle} >+</button>
        <button id='minus' className='btn' style={{padding: '0px 5px 0px 5px', border: 'none' }} onClick={qtyHandle} >-</button>
        <button id={item.cartItemId} className='btn' onClick={rmv}>remove</button>
      </div>
    </div>
  )
}

export default CartItem;
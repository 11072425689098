import React, { useEffect, useState } from "react";
import { readSupplier } from "../../utils.js/api";

function OrderSuccessItem( { item } ) {

  const [supplier, setSupplier] = useState({});
  useEffect(() => {
    readSupplier(item?.supplier)
      .then((res) => setSupplier(res))
  }, [item.supplier])

  return (
    <div style={{display: 'flex', flexDirection: 'column' }} >
      <div >
        <img src={item?.attributes?.colorSelected?.image} style={{width: '15%' }} alt='item' />
      </div>
      <div >{supplier.name}</div>
      <div >
        {item.name} Item id: {item.id}
      </div>
      <div >Size: {item?.attributes?.sizeSelected}</div>
      <div >price: {item.price}</div>
      <div >qty: {item?.attributes?.qtySelected} </div>
    </div>
  );
}

export default OrderSuccessItem;
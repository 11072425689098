import React, { useState } from "react";
import styles from './Home.module.css';
import octavius from '../image/octavius.webp';
import sneakers from '../image/sneakers.webp';
import bandw from '../image/bandw.webp';
import Parallax from "../components/Home/Parallax";
import sideLeaves from '../image/side-leaves.webp';
import sideLeavesRight from '../image/side-leaves-right.webp';

function Home() {

  const [pageLoaded, setPageLoaded] = useState(false)
  window.addEventListener('load', function () {
    setPageLoaded(true)
  })

  console.log(pageLoaded)

  return (
    <>

    <Parallax />
      {/*
      {!pageLoaded ?
        <div style={{width: '100vw', height: '100vh', position: 'fixed', zIndex: 1000, backgroundColor: 'white' }} >
          <img src={plant} style={{margin: 'auto', width: '150px', marginTop: '19vh' }} />
          </div>
        :
        null
      }
      */}
    <div className={styles.container} >
  
      <div style={{fontFamily: "'Caveat', cursive", fontSize: '1.5em', position: 'relative', width: '100%', zIndex: 0}} >Discover Sustainable Style</div>

      <div className={styles['landscape-container']} >
        <img src={sneakers} className={styles['landscape-image']} loading='lazy' /> {/* aspect ratio 3:2 */}
        <div style={{position: 'absolute', bottom: '0px', zIndex: 1 }}>New Styles</div>
      </div>

      <div className={styles.dividers} >
        
      </div>

      <h3 style={{fontFamily: "'Caveat', cursive", position: 'relative', zIndex: 1, fontSize: '1.5em' }} >Shop Environmentally Conscious Brands</h3>

      <div className={styles.portraits} >
        <div className={styles['portrait-container']} >
          <img src={bandw} className={styles['portrait-image']} loading='lazy' /> {/* aspect ratio 2:3 */}
          <div style={{position: 'absolute', bottom: '0px' }}>Brand</div>
        </div>
        <div className={styles['portrait-container']} >
          <img src={octavius} className={styles['portrait-image']} loading='lazy' /> 
          <div style={{position: 'absolute', bottom: '0px' }}>Brand</div>
        </div>
      </div>
      

    </div>

    </>
  )
}

export default Home;
import React, { useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProcessorStripe from "./ProcessorStripe";
const KEY_STRIPE_PUBLISHABLE = process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY;

function UserPayment( { shipping } ) {

  const stripeLoaded = loadStripe(KEY_STRIPE_PUBLISHABLE);

  const [billingShippingSame, setBillingShippingSame] = useState(true)

  const addBilling = {
    ...shipping
  }

  

  return (
    <div >
      <div style={{margin: '15px' }} >
        <div >
          contact: {shipping.email}
        </div>
        <div >
          ship to: {`${shipping.stAddress}, ${shipping.city} ${shipping.province} ${shipping.zip}, United States`}
        </div>
        <div >
          method: seller shipping method {"(tracking provided upon checkout)"}
        </div>
      </div>
      <div style={{textAlign: 'left' }} >
        Billing Address:
        <br/>
        <div style={{display: 'flex', flexDirection: 'column' }} >
          <div >
            <label >Same as my shipping address</label>
            <input type='radio' onClick={() => setBillingShippingSame(true)} checked={billingShippingSame} />
          </div>
          <div >
            <label >Different billing address</label>
            <input type='radio' onClick={() => setBillingShippingSame(false)} checked={!billingShippingSame} />
          </div>
        </div>
      </div>
      {!billingShippingSame ?
        <div >
          hi
        </div>
        :
        null
      }
      <Elements stripe={stripeLoaded} >
        <ProcessorStripe />
      </Elements>
    </div>
  );
}

export default UserPayment;
import React, { useEffect, useState } from "react";
import '../../App.css';
import styles from './Information.module.css';
import { userfrontUpdateInformation } from "../../utils.js/api";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function Information({ user, setUser }) {
    
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(null);

  const [nameField, setNameField] = useState('');
  const [phoneField, setPhoneField] = useState('');
  useEffect(() => {
    setNameField(user?.name);
    setPhoneField(user?.phoneNumber);
  }, [user]);

  const informationSubmit = (event) => {
    event.preventDefault();
    const userInformation = {
      name: nameField,
      phoneNumber: phoneField
    };
    userfrontUpdateInformation(userInformation, user.userId)
      .then((res) => {
        if (res?.error) {
          setError(res.message);
        } else {
          setUser(res);
          setEditable(false);
        }
      })
  };

  if (editable) {
    return (
      <form
        id='form-information'
        className={styles.form}
        onSubmit={informationSubmit}
        onKeyDown={(e) => e.key === 'Enter' ? document.getElementById('form-information').requestSubmit(document.getElementById('information-submit')) : null}
      >
        {error}

        <div style={{alignSelf: 'center' }}>email: {user.email} </div>

        <div className='input-wrp' >
          <br/>
          <input type='text' className='input-wrp-text' value={nameField} onChange={(e) => setNameField(e.target.value)} required />
          <span className='float-label' >Name</span>
        </div>

        <div className='input-wrp' >
          <br/>
          <input type='text' className='input-wrp-text' value={phoneField} onChange={(e) => setPhoneField(e.target.value)} required />
          <span className='float-label' >Phone Number</span>
        </div>

        <div>
          <button className='btn' onClick={() => setEditable(false)}>cancel</button>
          <button id='information-submit' className='btn' type="submit">submit</button>
        </div>
      </form>
    );
  } else {
    return (
      <div style={{margin: '15px' }} >
        <div>email: {user?.email}</div>
        <div>name: {user?.name}</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          phone number: {user?.phoneNumber}
        </div>
        <button className='btn' onClick={() => setEditable(true)}>edit</button>
      </div>
    );
  }
}

export default Information;

import React, { useContext, useEffect, useState } from "react";
import styles from './CheckoutStripe.module.css';
import { UserCartContext } from "../context/AppContext";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { newPaymentIntent } from "../utils.js/api";
import Form from "../components/CheckoutStripe/Form";
const KEY_STRIPE_PUBLISHABLE = process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY;
loadStripe.setLoadParameters({advancedFraudSignals: false});

function CheckoutStripe() {

  const { userCart } = useContext(UserCartContext);
  console.log(userCart)
  const [total, setTotal] = useState(
    userCart.reduce((total, cur) => {
      return total + cur.price * cur.attributes.qtySelected;
    }, 0)
  );
  useEffect(() => {
    setTotal(
      userCart.reduce((total, cur) => {
        return total + cur.price * cur.attributes.qtySelected;
      }, 0)
    );
  }, [userCart]);

  // -------- logic stripe

  const stripeLoaded = loadStripe(KEY_STRIPE_PUBLISHABLE);

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    newPaymentIntent(userCart)
      .then((data) => setClientSecret(data.clientSecret));
  }, [total])
  
  const stripeOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe'
    }
  }

  console.log(clientSecret)

  return (
    <div className={styles.container} >
      <div className={styles['left-container']} >
        {clientSecret ? 
          <Elements options={stripeOptions} stripe={stripeLoaded} >
            <Form />
          </Elements>
          :
          null
        }
      </div>
      <div className={styles["right-container"]}>
        total: {total}
        <br />
        <div style={{ fontSize: ".8em" }}>
          {"(shipping / tax not calculated)"}
        </div>
      </div>
    </div>
  )
}

export default CheckoutStripe;
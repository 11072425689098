import React, { useEffect, useState } from "react";
import "../../App.css";
import styles from "./Addresses.module.css";
import { userfrontUpdateAddresses } from "../../utils.js/api";

function Addresses({ user, setUser }) {
  const [editable, setEditable] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [stAddress, setStAddress] = useState("");
  const [city, setCity] = useState("");
  const [countryRegion, setCountryRegion] = useState("");
  const [province, setProvince] = useState("");
  const [zip, setZip] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    setFirstName(user?.data?.addresses?.firstName);
    setLastName(user?.data?.addresses?.lastName);
    setStAddress(user?.data?.addresses?.stAddress);
    setCity(user?.data?.addresses?.city);
    setCountryRegion(user?.data?.addresses?.countryRegion);
    setProvince(user?.data?.addresses?.province);
    setZip(user?.data?.addresses?.zip);
    setPhoneNumber(user?.data?.addresses?.phoneNumber);
  }, [user]);

  const formSubmit = (event) => {
    event.preventDefault();
    const form = {
      firstName: firstName,
      lastName: lastName,
      stAddress: stAddress,
      city: city,
      countryRegion: countryRegion,
      province: province,
      zip: zip,
      phoneNumber: phoneNumber,
    };

    userfrontUpdateAddresses(form).then(setUser).then(setEditable(false));

    for (let i of event.target.field) {
      i.value = "";
    }
  };

  if (editable) {
    return (
      <form
        id="form-address"
        className={styles.form}
        onSubmit={formSubmit}
        onKeyDown={(e) =>
          e.key === "Enter"
            ? document
                .getElementById("form-address")
                .requestSubmit(document.getElementById("add-submit"))
            : null
        }
      >

        <div className={styles['form-group']} >
          <div className='input-wrp' >
            <br/>
            <input id="first-name" name="field" type='text' className='input-wrp-text' value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            <span className='float-label' >First Name</span>
          </div>

          <div className='input-wrp' >
            <br/>
            <input id="last-name" name="field" type='text' className='input-wrp-text' value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            <span className='float-label' >Last Name</span>
          </div>
        </div>
        <div className='input-wrp' >
          <br/>
          <input id="st-address" name="field" type='text' className='input-wrp-text' value={stAddress} onChange={(e) => setStAddress(e.target.value)} required />
          <span className='float-label' >St Address</span>
        </div>

        <div className='input-wrp' >
          <br/>
          <input id="city" name="field" type='text' className='input-wrp-text' value={city} onChange={(e) => setCity(e.target.value)} required />
          <span className='float-label' >City</span>
        </div>

        <div className='input-wrp' >
          <br/>
          <input id="province" name="field" type='text' className='input-wrp-text' value={province} onChange={(e) => setProvince(e.target.value)} required />
          <span className='float-label' >Province</span>
        </div>

        <div className='input-wrp' >
          <br/>
          <input id="zip" name="field" type='text' className='input-wrp-text' value={zip} onChange={(e) => setZip(e.target.value)} required />
          <span className='float-label' >Zip</span>
        </div>

        <div className='input-wrp' >
          <br/>
          <input id="phone-number" name="field" type='text' className='input-wrp-text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          <span className='float-label' >Zip</span>
        </div>

        <div>
          <button className="btn" onClick={() => setEditable(false)}>
            cancel
          </button>
          <button id="add-submit" className="btn" type="submit">
            submit
          </button>
        </div>
      </form>
    );
  } else {
    return (
      <div style={{margin: '15px' }} >
        default address:
        <hr style={{width: '30%', marginBottom: '0px', marginTop: '0px' }} />
        <ul style={{ listStyle: 'none', paddingLeft: '0px', margin: '5px' }} >
          <li>
            {firstName} {lastName}
          </li>
          <li>{stAddress}</li>
          <li>{city}</li>
          <li>{countryRegion}</li>
          <li>{province}</li>
          <li>{zip}</li>
          <li>{phoneNumber}</li>
        </ul>
        <button className="btn" onClick={() => setEditable(true)}>
          edit address
        </button>
      </div>
    );
  }
}

export default Addresses;
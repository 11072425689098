import React, { useContext, useEffect, useState } from "react";
import styles from './Checkout.module.css';
import { UserCartContext } from "../context/AppContext";
import UserPayment from "../components/Checkout/UserPayment";
import { useLocation } from "react-router";

function Checkout() {

  const shipping = useLocation().state.shipping;
  console.log(shipping)

  const { userCart } = useContext(UserCartContext);
  const [total, setTotal] = useState(
    userCart.reduce((total, cur) => {
      return total + cur.price * cur.attributes.qtySelected;
    }, 0)
  );
  useEffect(() => {
    setTotal(
      userCart.reduce((total, cur) => {
        return total + cur.price * cur.attributes.qtySelected;
      }, 0)
    );
  }, [userCart]);

  return (
    <div className={styles.container} >
      <div className={styles['left-container']} >
        <UserPayment shipping={shipping} />
      </div>
      <div className={styles["right-container"]}>
        total: {total}
        <br />
        <div style={{ fontSize: ".8em" }}>
          {"(shipping / tax not calculated)"}
        </div>
      </div>
    </div>
  )
}

export default Checkout;

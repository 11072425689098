import React, { useEffect, useContext, useState } from "react";
import { UserCartContext } from "../context/AppContext";
import { useNavigate } from "react-router";
import "../App.css";
import styles from "./Shipping.module.css";
import { userfrontGetUser } from "../utils.js/api";
import Userfront from "@userfront/core";
Userfront.init("rbvzmr6b");

function Shipping() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  useEffect(() => {
    userfrontGetUser(Userfront.accessToken()).then(setUser);
  }, []);
  useEffect(() => {
    setEmail(user?.email);
    setFirstName(user?.data?.addresses?.firstName);
    setLastName(user?.data?.addresses?.lastName);
    setStAddress(user?.data?.addresses?.stAddress);
    setCity(user?.data?.addresses?.city);
    setProvince(user?.data?.addresses?.province);
    setZip(user?.data?.addresses?.zip);
    setPhoneNumber(user?.data?.addresses?.phoneNumber);
  }, [user]);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [stAddress, setStAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zip, setZip] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const clear = () => {
    const allInputs = document.querySelectorAll('[name="field"]');
    for (let input of allInputs) {
      input.value = "";
    }
  };

  const { userCart } = useContext(UserCartContext);
  const [total, setTotal] = useState(
    userCart.reduce((total, cur) => {
      return total + cur.price * cur.attributes.qtySelected;
    }, 0)
  );
  useEffect(() => {
    setTotal(
      userCart.reduce((total, cur) => {
        return total + cur.price * cur.attributes.qtySelected;
      }, 0)
    );
  }, [userCart]);

  const formSubmit = (event) => {
    event.preventDefault();
    console.log(firstName)
    navigate('/checkout', {
      state: {
        shipping: {
          email,
          phoneNumber,
          firstName,
          lastName,
          stAddress,
          city,
          province,
          zip
        }
      }
    })
  };

  return (
    <form id='form-shipping' onSubmit={formSubmit} className={styles.form} >
      
      <div className={styles["left-container"]}>

        <div >
          {!user ? (
            <div>
              Do you have a pre-existing account?
              <button
                className="btn"
                style={{marginTop: '0px' }}
                onClick={() => {
                  navigate("/login", {
                    state: {
                      fromCheckout: true,
                    },
                  });
                }}
              >
                login
              </button>
            </div>
          ) : (
            <div >
              clear address and information?
              <button onClick={clear} style={{marginTop: '0px' }} className="btn">
                clear
              </button>
            </div>
          )}
        </div>

        <div style={{ alignSelf: "center", fontWeight: 'bold' }}>Contact Information:</div>
        <div className='input-wrp' >
          <br/>
          <input type='text' className='input-wrp-text' value={email} name="field" onChange={(e) => setEmail(e.target.value)} required />
          <span className='float-label' >Email*</span>
        </div>
        <div className='input-wrp' >
          <br/>
          <input type='text' className='input-wrp-text' value={phoneNumber} name="field" onChange={(e) => setPhoneNumber(e.target.value)} required />
          <span className='float-label' >Phone Number*</span>
        </div>

        <label>Subscribe for news and offer emails?</label>
        <input type="checkbox" />

        <div style={{alignSelf: 'center', fontWeight: 'bold' }} >Shipping Address:</div>
        <input type="text" className={styles.input} value="United States" readOnly required />
        <div className={styles['form-group']} >
          <div className='input-wrp' >
            <br/>
            <input type='text' className='input-wrp-text' style={{width: '100%', boxSizing: 'border-box' }} value={firstName} name="field" onChange={(e) => setFirstName(e.target.value)} required />
            <span className='float-label' >First Name*</span>
          </div>
          <div className='input-wrp' >
            <br/>
            <input type='text' className='input-wrp-text' style={{width: '100%', boxSizing: 'border-box' }} value={lastName} name="field" onChange={(e) => setLastName(e.target.value)} required />
            <span className='float-label' >Last Name*</span>
          </div>
        </div>
        
        <div className='input-wrp' >
          <br/>
          <input type='text' className='input-wrp-text' value={stAddress} name="field" onChange={(e) => setStAddress(e.target.value)} required />
          <span className='float-label' >St Address*</span>
        </div>

        <div className={styles['form-group']} >
          <div className='input-wrp' >
            <br/>
            <input type='text' className='input-wrp-text' value={city} name="field" onChange={(e) => setCity(e.target.value)} required />
            <span className='float-label' >City*</span>
          </div>
          <div className='input-wrp' >
            <br/>
            <select value={province} className='input-wrp-text' style={{paddingLeft: '3px' }} name="field" required >
              <option value="" disabled selected hidden>Please Choose...</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <span className='float-label' style={{top: '23px', left: '8px', fontSize: '12px', opacity: '1' }} >State*</span>
          </div>
          <div className='input-wrp' >
            <br/>
            <input type='text' className='input-wrp-text' value={zip} name="field" onChange={(e) => setZip(e.target.value)} required />
            <span className='float-label' >Zip*</span>
          </div>
        </div>

        <label>Shipping Method:</label>
        <label>
          seller shipping method {"(tracking provided upon checkout)"}
          <input type="radio" checked readOnly />
        </label>

      </div>

      <div className={styles["right-container"]}>
        total: {total}
        <br />
        <div style={{ fontSize: ".8em" }}>
          {"(shipping / tax not calculated)"}
        </div>
        {userCart.length > 0 ? (
        <button type='submit' className="btn">
          Continue to payment
        </button>
        ) : null}
      </div>

    </form>
  );
}

export default Shipping;

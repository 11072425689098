import React from "react";

function Wishlist() {
  return (
    <>
      wishlist
    </>
  );
}

export default Wishlist;
import React, { useEffect } from "react";
import '../../App.css';
import styles from './SizeColor.module.css';

function SizeColor( { sizeColorQty, setDisplayedImage, id, curSelection, setCurSelection, setSizeUnselectedError } ) {

  const btnSelectedStyle = {
    backgroundColor: '#292929',
    color: 'white',
    marginLeft: '0px',
    marginRight: '10px',
  };

  useEffect(() => {
    if (curSelection.sizeSelected) {
      setSizeUnselectedError(false);
    }
  }, [curSelection, setSizeUnselectedError])
  
  function colorOptions() {
    const colorArray = [];
    for (let i of sizeColorQty) {
      colorArray.push(
        <button 
          key={`${id}${i.color}`} 
          className='btn' 
          style={curSelection.colorSelected.color === i.color ? btnSelectedStyle : {marginLeft: '0px', marginRight: '10px' } }
          onClick={() => {
          setDisplayedImage(i.image);
          setCurSelection((cur) => {
            return {...cur, colorSelected: i, qtySelected: 1}
          })
        }} >{i.color}</button>
      );
    }
    return colorArray;
  }

  function sizeOptions() {
    let sizeArray = [];
    for (let size of curSelection.colorSelected.sizes) {
      if (size[1] > 0) {
        sizeArray.push(
          <button 
            name={size[0]} 
            className='btn' 
            style={size[0] === curSelection.sizeSelected ? btnSelectedStyle : {marginLeft: '0px', marginRight: '10px' } }
            key={`${id}${size[0]}`} 
            onClick={() => {
            setCurSelection((cur) => {
              return {...cur, sizeSelected: size[0], qtySelected: 1}
            })
          }} >{size[0]}</button>
        )
      } else {
        sizeArray.push(
          <button key={`${id}${size[0]}`} className='btn' style={{marginLeft: '0px', marginRight: '10px' }} disabled >{size[0]}</button>
        )
      }
    }
    
    return sizeArray;
  }

  const qtyHandle = (event) => {

    if (!curSelection.sizeSelected) {
      setSizeUnselectedError(true);
      return;
    }

    if (event.target.id === 'plus') {
      if (curSelection.qtySelected + 1 > getQtyMax()) {
        return
      } else {
        setCurSelection((cur) => {
          return {...cur, qtySelected: cur.qtySelected + 1}
        });
      }
    }

    if (event.target.id === 'minus') {
      if (curSelection.qtySelected - 1 < 1) {
        return
      } else {
        setCurSelection((cur) => {
          return {...cur, qtySelected: cur.qtySelected - 1}
        });
      }
    }

  }

  function getQtyMax() {
    let qtyMaxed = 1;
    const filtered = curSelection.colorSelected.sizes.filter((item) => {
      return item[0] === curSelection.sizeSelected
    })[0];
    if (filtered) qtyMaxed = filtered[1];
    return qtyMaxed;
  }
  
  return (
    <div className={styles.container} >
      <div className={styles['color-container']} >
        <div className={styles.color} >Color:</div>
        <div style={{textAlign: 'left' }} >
          {colorOptions()}
        </div>
      </div>
      <div className={styles['sizes-container']} >
        <div className={styles.sizes} >Sizes:</div>
        <div style={{textAlign: 'left' }} >
          {sizeOptions()}
        </div>
      </div>
      <div className={styles['quantity-container']} >
        <div className={styles.quantity} >Quantity</div>
        <div style={{textAlign: 'left' }} >
          <input id='qty' type='number' className={styles['qty-input']} min={1} max={getQtyMax()} step='1' value={curSelection.qtySelected} onChange={qtyHandle} readOnly />
          <button id='plus' className='btn' style={{padding: '0px 5px 0px 5px', border: 'none', borderRadius: '3px' }} onClick={qtyHandle} >+</button>
          <button id='minus' className='btn' style={{padding: '0px 5px 0px 5px', border: 'none', borderRadius: '3px' }} onClick={qtyHandle} >-</button>
        </div>
      </div>
    </div>
  )
}

export default SizeColor;
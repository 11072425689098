import React from "react";
import '../../App.css';
import styles from './PageNavigator.module.css';

function PageNavigator( { queryParams, setQueryParams, page, pageLimit, count } ) {
  
  const pageFinal = Math.ceil(count / pageLimit);

  const pageCurStyle = {
    border: 'solid black 1px'
  }

  function pageNumbers() {
    const pages = [];
    if (pageFinal < 5) {
      for (let i = 2; i <= pageFinal - 1; i++) {
        pages.push(
          <button key={`pageButton${i}`} className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === i ? pageCurStyle : null} onClick={() => setQueryParams({...Object.fromEntries(queryParams), page: i})} >
            {i}
          </button>
        );
      }
      return pages
    } else if (page < 5) {
      for (let i = 2; i <= page + 2; i++) {
        pages.push(
          <button key={`pageButton${i}`} className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === i ? pageCurStyle : null} onClick={() => setQueryParams({...Object.fromEntries(queryParams), page: i})} >
            {i}
          </button>
        );
      }
      return pages;
    } else if (pageFinal - page < 3) {
      for (let i = pageFinal - 5; i < pageFinal; i++) {
        pages.push(
          <button key={`pageButton${i}`} className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === i ? pageCurStyle : null} onClick={() => setQueryParams({...Object.fromEntries(queryParams), page: i})} >
            {i}
          </button>
        );
      }
      return pages;
    } else {
      for (let i = page - 2; i < page + 3; i++) {
        pages.push(
          <button key={`pageButton${i}`} className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === i ? pageCurStyle : null} onClick={() => setQueryParams({...Object.fromEntries(queryParams), page: i})} >
            {i}
          </button>
        );
      }
      return pages;
    }
  }

  const pageForward = () => {
    if (page < pageFinal) {
      setQueryParams({...Object.fromEntries(queryParams), page: Number(queryParams.get('page')) + 1});
    }
  }
  const pageBackward = () => {
    if (page > 1) {
      setQueryParams({...Object.fromEntries(queryParams), page: Number(queryParams.get('page')) - 1});
    }
  }
  
  return (
    <div>
      <button className='btn' onClick={pageBackward}>prev</button>
      <button className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === 1 ? pageCurStyle : null} onClick={() => setQueryParams({ ...Object.fromEntries(queryParams), page: 1 })}>
        1
      </button>
      {page >= 5 ? "..." : null}
      {pageNumbers()}
      {pageFinal - page > 3 ? "..." : null}
      {pageFinal !== 0 && pageFinal !== 1 ? 
        <button className={`btn ${styles.num}`} style={Number(queryParams.get('page')) === pageFinal ? pageCurStyle : null} onClick={() => setQueryParams({ ...Object.fromEntries(queryParams), page: pageFinal })} >
          {pageFinal}
        </button>
        :
        null}
      <button className='btn' onClick={pageForward}>next</button>
      <div >
        items: {count}
      </div>
    </div>
  );
}

export default PageNavigator;

import React, { useEffect, useState } from "react";
import styles from "./MyAccount.module.css";
import Information from "../components/MyAccount/Information";
import Addresses from "../components/MyAccount/Addresses";
import MyOrders from "../components/MyAccount/MyOrders";
import { userfrontGetUser } from "../utils.js/api";
import UserLogout from "../components/MyAccount/UserLogout";
import EmailPreferences from "../components/MyAccount/EmailPreferences";
import Userfront from "@userfront/core";
Userfront.init("rbvzmr6b");

function Accounts() {
  const [user, setUser] = useState({});
  useEffect(() => {
    userfrontGetUser(Userfront.accessToken()).then(setUser);
  }, []);

  if (
    Userfront.accessToken() &&
    Object.keys(Userfront.accessToken()).length > 0
  ) {
    return (
      <div>
        <h3 style={{marginTop: '50px'}} >My Account</h3>

        <div className={styles.sides} >
          <div className={styles['left-container']} >
            <div style={{ border: "solid gray 1px" }}>
              <div style={{ margin: "5px", fontWeight: "bold" }}>
                Information:
              </div>
              <Information
                user={user}
                setUser={setUser}
                userAccessToken={Userfront.accessToken()}
              />
            </div>

            <div style={{ border: "solid gray 1px" }}>
              <div style={{ margin: "5px", fontWeight: "bold" }}>
                Addresses:
              </div>
              <Addresses
                user={user}
                setUser={setUser}
                userAccessToken={Userfront.accessToken()}
              />
            </div>

            <div style={{ border: "solid gray 1px" }}>
              <div style={{ margin: "5px", fontWeight: "bold" }}>
                Email Preferences:
              </div>
              <EmailPreferences user={user} />
            </div>
          </div>

          <div className={styles['right-container']} >
            <div style={{ border: "solid gray 1px", width: "100%" }}>
              <div style={{ margin: "5px", fontWeight: "bold" }}>
                My Orders:
              </div>
              <MyOrders
                user={user}
                setUser={setUser}
                userAccessToken={Userfront.accessToken()}
              />
            </div>
          </div>
        </div>

        <hr className={styles.separator} />
        <div>
          member since: {user?.createdAt?.slice(0, user.createdAt.indexOf("T"))}
        </div>

        <div>
          <UserLogout />
        </div>
      </div>
    );
  }
}

export default Accounts;

import React from "react";
import '../App.css';
import styles from './ItemSearch.module.css';
import { useNavigate } from "react-router";
import glass from '../image/src.webp';

function ItemSearch( { setSmallScreenSrc } ) {

  const navigate = useNavigate();

  const formSubmit = (event) => {
    event.preventDefault();
    const array = event.target.src.value.split(' ');
    const str = array.join('+');
    navigate(`/shop?page=1&src=${str}`)
    event.target.src.value = null;
  }

  return (
      <form onSubmit={formSubmit} className={styles.container} >
        <input id='src' type="text" className={styles.input} placeholder='search' onBlur={() => setSmallScreenSrc(false)} />
        <button type='submit' className={styles['icon-container']} ><img src={glass} className={styles.icon} /></button>
      </form>
  );
}

export default ItemSearch;
